const config = {
  ACTIVO: 1,
  VENDIDO: 2,
  EXPIRADO: 3,
  INACTIVO: 4,
  ENTREGADO: 5,
  RECHAZADO: 6,
  //baseUrl: "http://192.168.0.5/subastapp/public/api", //no trialing slash here
  //baseUrl: "http://127.0.0.1:8000/api", //no trialing slash here
  baseUrl: "https://subastapp.subastaapp.com/api", //no trialing slash here
  customStrings: {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Buscar",
    "selectAll": "Todos",
    "selectSomeItems": "Seleccionar destinatario..."
  }
};
  
export default config;