import { Component, useState, useMemo } from "react";
import styles from "./Question.module.css";
import NavBar from "../../Navbar/Navbar";
import formTitle from "../../../images/question_form_title.png";
import { useParams } from 'react-router-dom';
import { useApiRequest } from '../../../useApiRequest';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from 'axios';
import config from '../../../config';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";

const Question = (id) => {
    const [message, setMessage] = useState();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isActive, setIsActive] = useState(false);
    const params = useParams()

    const { data, loading, error } = useApiRequest(`/knowledgebase/${params.id}`);
    console.log(data)

    const onSubmit = (data, e) => {
        setMessage({
            data: "Guardando el registro...",
            type: "alert-warning",
        });

        fetch(`${config.baseUrl}/knowledgebase/${params.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                const hasError = !data.success;

                if (data.success === true) {
                    swal("Se ha actualizado el registro", { icon: "success", }).then((value) => {
                        window.location.reload(false);
                    });
                }
            });
    };

    return (
        <div className={styles.product_page}>
            <NavBar />
            <div className="row" style={{ marginTop: "30px", width: "100%" }}>
                <div className="col-md-12">
                    <div className="card" style={{ backgroundColor: "transparent", border: 0 }}>
                        <img id="titleImg" alt="" src={formTitle} style={{ width: 400, marginLeft: "16%" }} />
                        <div className={`card-body ${styles.rounded}`}>
                            <div className={styles.loginFormContainer}>

                                {message && (
                                    <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                        {message.data}
                                        <span
                                            aria-hidden="true"
                                            className="ml-auto cursor-pointer"
                                            onClick={() => setMessage(null)}>
                                            &times;
                                        </span>
                                    </div>
                                )}

                                <fieldset className="p-3 rounded">
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                        <div className="form-group">
                                            <label htmlFor="question">Pregunta</label>
                                            <input
                                                type="text"
                                                name="question"
                                                rows="10"
                                                maxLength="400"
                                                className={`form-control ${styles.form_control_rounded}`}
                                                id="inputForquestion"
                                                defaultValue={data.data ? data.data.question : ''}
                                                placeholder="Escribe la pregunta"
                                                {...register('question', { required: true })}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="answer">Respuesta</label>
                                            <textarea
                                                type="text"
                                                name="answer"
                                                rows="10"
                                                maxLength="400"
                                                className={`form-control ${styles.form_control_rounded}`}
                                                id="inputForanswer"
                                                defaultValue={data.data ? data.data.answer : ''}
                                                placeholder="Escribe la respuesta"
                                                {...register('answer', { required: true })}
                                            />
                                        </div>

                                        <div>
                                            <button type="submit" className={styles.btn_grad} style={{ position: "absolute", right: "7%", bottom: "1%" }}>
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Question;