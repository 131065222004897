import React, { useEffect, useState } from 'react';
import styles from "./CountriesDropDown.module.css";
import config from '../config';

const CountriesDropDown = (props) => {
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch(`${config.baseUrl}/countries`);
            const countriesData = await response.json();

            const options = countriesData.map((d) => ({
                "value": d.id,
                "label": d.name
            }))

            countries.splice(0, countries.length)
            setCountries(options);
        };
        fetchCountries();
    }, []);

    const handleChange = (event) => {
        props.onchange(event);
    }

    console.log(props)

    return (
        <select name={props.name} className={`form-control ${styles.form_control_country_rounded}`} disabled={true} value={props.value}>
            {countries.map(c => (
                <option key={c.value} value={c.value}>{c.label}</option>
            ))}
        </select>
    );
};

export default CountriesDropDown;