import { Component, useState, useMemo } from "react";
import styles from "./Product.module.css";
import NavBar from "../../Navbar/Navbar";
import formTitle from "../../../images/product_form_title.png";
import { useParams } from 'react-router-dom';
import {useApiRequest} from '../../../useApiRequest';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from 'axios';
import config from '../../../config';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

const Product = (id) => {
    const [isActive, setIsActive] = useState(false);
    const params = useParams()
    const history = useHistory();

    const { data, loading, error } = useApiRequest(`/products/showProduct/${params.id}`);
    console.log(data)

    const pictures = data.data ? data.data.pictures.map(picture => ({
        "original": picture.picture,
        "thumbnail": picture.picture
    })) : [];

    useMemo(() => {
        setIsActive(data.data ? (data.data.state === config.ACTIVO) : false)
    }, [data]);

    function toggleState() {
        let action = isActive ? 'deactivate' : 'activate'

        axios.put(`${config.baseUrl}/products/${action}/${params.id}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) 
                setIsActive(!isActive)
        }).catch(error => {
            swal(error.response.data.message);
            console.log(error.response.data)
        });
    }

    const consultarEliminar = () => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez eliminado, no se podrá revertir!",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                eliminar()
            }
          });
    }

    const eliminar = () => {
        axios.put(`${config.baseUrl}/products/delete/${params.id}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) {
                  swal("Se ha eliminado la subasta!", {icon: "success",}).then((value)=>{
                    history.push("/auctions");
                  });
            }
        }).catch(error => {
            swal(error.response.data.message);
            console.log(error.response.data)
        });
    }

    return (
        <div className={styles.product_page}>
            <NavBar />
            <div className="row" style={{marginTop:"30px", width:"100%"}}>
                <div className="col-md-12">
                    <div className="card" style={{backgroundColor:"transparent", border:0}}>
                        <img id="titleImg" alt="" src={formTitle} style={{width:400, marginLeft:"16%"}}/>
                        <div className={`card-body ${styles.rounded}`}>
                            <div className={styles.loginFormContainer}>
                                <fieldset className="p-3 rounded">
                                    <form noValidate autoComplete="off">
                                        <div className="form-group">
                                            <label htmlFor="name">Nombre</label>
                                            <input type="text" name="name" 
                                                className={`form-control ${styles.form_control_rounded}`} 
                                                value={data.data ? data.data.name : ''}
                                                readOnly={true}/>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label htmlFor="description">Descripción</label>
                                            <input type="text" name="description" 
                                                className={`form-control ${styles.form_control_rounded}`} 
                                                value={data.data ? data.data.description : ''}
                                                readOnly={true}/>
                                        </div>                                        
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="base_price">Precio Base</label>
                                                    <input type="text" name="base_price" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.base_price : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="quantity">Cantidad</label>
                                                    <input type="text" name="quantity" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.quantity : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                        </div>    

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="start_date">Inicio</label>
                                                    <input type="text" name="start_date" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.start_date : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="end_date">Fin</label>
                                                    <input type="text" name="end_date" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.end_date : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{marginBottom:"40px", marginTop:"40px"}}>
                                            <div className="col-md-6">
                                                <ImageGallery items={pictures} />
                                            </div>


                                            {/* Boton eliminar producto */}
                                            <div className="col-md-3">
                                                <button type="button" className="btn btn-danger" onClick={consultarEliminar}>Borrar</button>
                                            </div>
                                            
                                            <div className="col-md-3">
                                                {
                                                    isActive ? 
                                                        <div className="col-md-3">
                                                            <button type="button" className={styles.suspend_button} onClick={toggleState}></button>
                                                        </div> : 
                                                        <div className="col-md-3">
                                                            <button type="button" className={styles.activate_button} onClick={toggleState}></button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <button type="submit" className={styles.btn_grad} style={{position:"absolute", right:"7%", bottom:"1%"}}>
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Product;