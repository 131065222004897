import { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import logo from '../../images/logo.png'
import TransaccionesIcon from '../../images/transacciones_icon.png'
import notificacionesIcon from '../../images/notificaciones_icon.png'
import perfilesIcon from '../../images/perfiles_icon.png'
import salirIcon from '../../images/salir_icon.png'
import styles from "./Navbar.module.css";
import { NavLink } from 'react-router-dom';
import config from '../../config';

const NavBar = () => {
    const token = localStorage.getItem('token');
    const history = useHistory();

    const logout = async () => {
        const response = await fetch(`${config.baseUrl}/logout?token=` + token, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        });

        const content = await response.json();
        if(content.success) {
            localStorage.removeItem('token');
            history.push('/');
            //window.location.reload();
        }
    }

    return (
        <nav className={`navbar navbar-expand-md navbar-dark mb-4 ${styles.my_navbar}`}>
            <div className={`container-fluid ${styles.my_navbar}`}>
            <img src={logo} alt="Logo" style={{width:340}}/>
                <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                    <ul className="navbar-nav mb-2 mb-md-0">
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={TransaccionesIcon} alt="Logo" height="24px"/></span>
                            <NavLink activeClassName="active" to="/auctions" className="navbar-brand" style={{fontSize:"medium"}}> Subastas</NavLink>
                        </li>
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={TransaccionesIcon} alt="Logo" height="24px"/></span>
                            <NavLink activeClassName="active" to="/payments" className="navbar-brand" style={{fontSize:"medium"}}> Pagos</NavLink>
                        </li>
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={notificacionesIcon} alt="Logo" height="24px"/></span>
                            <NavLink activeClassName="active" to="/notifications" className="navbar-brand" style={{fontSize:"medium"}}> Notificaciones</NavLink>
                        </li>
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={perfilesIcon} alt="Logo" height="22px"/></span>
                            <NavLink activeClassName="active" to="/profiles" className="navbar-brand" style={{fontSize:"medium"}}> Perfiles</NavLink>
                        </li>
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={perfilesIcon} alt="Logo" height="22px"/></span>
                            <NavLink activeClassName="active" to="/questions" className="navbar-brand" style={{fontSize:"medium"}}> Preguntas</NavLink>
                        </li>
                        <li className="nav-item" style={{margin:"15px"}}>
                            <span><img src={salirIcon} alt="Logo" height="16px"/></span>
                            <NavLink activeClassName="active" to="/login" className="navbar-brand" onClick={logout} style={{fontSize:"medium"}}> Salir</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default NavBar;