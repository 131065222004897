import React, { useEffect, useState } from "react";
import styles from "./UserListComponent.module.css";
import { MultiSelect } from "react-multi-select-component";
import { useApiRequest } from "../../../useApiRequest";
import config from "../../../config";

const UserListComponent = React.memo(({ sendDataToParent }) => {
    const [selected, setSelected] = useState([]);
    const [selectListArray, setSelectListArray] = useState([]);
    const [usersArray] = useState([]);

    const { data, loading, error } = useApiRequest('/users');
    
    useEffect(() => {
        if (data.data != null) {
            selectListArray.splice(0, selectListArray.length)

            data.data.map(({ id, email, name, lastname, avatar_file }) => {
                const obj = { label: email, value: id, fullname: name + ' ' + lastname, avatar_file };
                selectListArray.push(obj);
            });
        }
    });

    const unaddedCartItems = selected.filter(addedItem => {
        return usersArray.filter(cartItem => cartItem.id !== addedItem.id);
    });

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={styles.multi_select}
                    options={selectListArray}
                    value={selected}
                    style={styles.rmsc}
                    onChange={setSelected}
                    overridingStrings={`${config.customStrings}`}
                    labelledBy="Seleccione un destinatario" />
            </div>

            <div>
                <ul className={styles.list}>
                    {unaddedCartItems.map(d => (
                        <li key={d.value} className={styles.list_item} onClick={() => { sendDataToParent(d.value); }} >
                            <div>
                                <img src={d.avatar_file} className={styles.list_item_image} />
                            </div>
                            <div className={styles.list_item_content}>
                                <h4>{d.fullname}</h4>
                                <p>Activo</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
})
export default UserListComponent;