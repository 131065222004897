import React, { useState } from "react";
import styles from "./Register.module.css";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import config from "../../config";
import CountriesDropDown from "../CountriesDropDown";
import InterestsDropDown from "../InterestsDropDown";

const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState();
    const [country_id, setCountry_id] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    const [interests, setInterests] = useState([]);
    const [interestsArray, setInterestsArray] = useState('');

    const onSubmit = (data, e) => {
        data.country_id = selectedCountry;
        data.interests = interestsArray;

        setMessage({
            data: "Registration is in progress...",
            type: "alert-warning",
        });

        console.log(data);

        fetch(`${config.baseUrl}/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((res) => res.json())
            .then((data) => {
                console.log(data);
                const hasError = !data.success;
                setMessage({
                    data: hasError ? data.error[0] : "Registered successfully",
                    type: hasError ? "alert-danger" : "alert-success",
                });

                !hasError && e.target.reset();
            });
    };

    const onchangeCountrySelect = (item) => {
        setCountry_id(item);
        setSelectedCountry(item.value);
    };

    const onChangeInterestsSelect = (item) => {
        setInterests(item);

        const values = item.map((i) => i.value).join(',');
        setInterestsArray(values)
    }

    return (
        <div className={`${styles.container} container-fluid d-flex align-items-center justify-content-center`}>
            <div className={styles.registrationFormContainer}>
                {message && (
                    <div className={`alert fade show d-flex ${message.type}`} role="alert">
                        {message.data}
                        <span
                            aria-hidden="true"
                            className="ml-auto cursor-pointer"
                            onClick={() => setMessage(null)}>
                            &times;
                        </span>
                    </div>
                )}
                <fieldset className="border p-3 rounded">
                    <legend className={`${styles.registrationFormLegend} border rounded p-1 text-center`}>
                        Registration Form
                    </legend>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="inputForEmail">Email address</label>
                            <span className="mandatory">*</span>
                            <input
                                id="inputForEmail"
                                name="email"
                                type="email"
                                className="form-control"
                                aria-describedby="Enter email address"
                                placeholder="Enter email address"
                                {
                                ...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter your email address",
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Enter a valid email address",
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "Minimum 6 characters are allowed",
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum 255 characters are allowed",
                                    },
                                })
                                }
                            />
                            {errors.email && (
                                <span className={`${styles.errorMessage} mandatory`}>
                                    {errors.email.message}
                                </span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputForName">Your Name</label>
                            <span className="mandatory">*</span>
                            <input
                                id="inputForName"
                                name="name"
                                type="text"
                                className="form-control"
                                aria-describedby="Enter your name"
                                placeholder="Enter your name"
                                {
                                ...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your name",
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "Minimum 6 characters are allowed",
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum 255 characters are allowed",
                                    },
                                })
                                }
                            />
                            {errors.name && (
                                <span className={`${styles.errorMessage} mandatory`}>
                                    {errors.name.message}
                                </span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputForLastname">Your Last Name</label>
                            <span className="mandatory">*</span>
                            <input
                                id="inputForLastname"
                                name="lastname"
                                type="text"
                                className="form-control"
                                aria-describedby="Enter your last name"
                                placeholder="Enter your last name"
                                {
                                ...register('lastname', {
                                    required: {
                                        value: true,
                                        message: "Please enter your last name",
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "Minimum 6 characters are allowed",
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum 255 characters are allowed",
                                    },
                                })
                                }
                            />
                            {errors.lastname && (
                                <span className={`${styles.errorMessage} mandatory`}>
                                    {errors.lastname.message}
                                </span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputForPassword">Password</label>
                            <span className="mandatory">*</span>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                id="inputForPassword"
                                placeholder="Enter password"
                                {
                                ...register('password', {
                                    required: {
                                        value: true,
                                        message: "Please enter password",
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "Minimum 6 characters are allowed",
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum 255 characters are allowed",
                                    },
                                })
                                }
                            />
                            {errors.password && (
                                <span className={`${styles.errorMessage} mandatory`}>
                                    {errors.password.message}
                                </span>
                            )}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="inputForPhone">Your Phone</label>
                            <span className="mandatory">*</span>
                            <input
                                id="inputForPhone"
                                name="phone"
                                type="number"
                                className="form-control"
                                aria-describedby="Enter your phone"
                                placeholder="Enter your phone"
                                {
                                ...register('phone', {
                                    required: {
                                        value: true,
                                        message: "Please enter your phone",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "Minimum 8 characters are allowed",
                                    },
                                    maxLength: {
                                        value: 12,
                                        message: "Maximum 12 characters are allowed",
                                    },
                                })
                                }
                            />
                            {errors.phone && (
                                <span className={`${styles.errorMessage} mandatory`}>
                                    {errors.phone.message}
                                </span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputForCountry">Your Country</label>
                            <span className="mandatory">*</span>
                            <CountriesDropDown name="country_id" onchange={(e) => { onchangeCountrySelect(e) }} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputForInterests">Your Interests</label>
                            <span className="mandatory">*</span>
                            <InterestsDropDown onchange={(e) => { onChangeInterestsSelect(e) }} />
                        </div>

                        <br/>

                        <div className="d-flex align-items-center justify-content-center">
                            <button type="submit" className="btn btn-outline-primary">
                                Submit
                            </button>
                            <button className="btn btn-link">
                                <Link to="/login">Cancel</Link>
                            </button>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    );
};

export default Register;